import React from "react";
import styled from "styled-components";

const ProjectItemStyles = styled.div`
  margin-top: 50px;

  .container{
    margin-top: 50px;
    margin-left: -25px;
  }
`

function usps1() {

  return (
    <ProjectItemStyles>
      <div className="about">
        <h2>A summary of several interesting projects from my time on the USPS Geospatial Analytics team. No pictures or in depth explanation available here due to the nature of the work and data involved.</h2>
        <div className="container">
         <h2><b>National Transportation Routes with Databricks</b></h2>
            <p>By leveraging a Databricks python environment I was able to work on generating largescale sets of transportation routelines between and to/from Postal facilities asynchronously with the ArcServer API. This data was pulled from various web and csv sources and processed into pandas and geopandas Dataframes. It was then exported from the Databricks environment to ArcPro and published to a web layer for analysis.</p>
          <h2><b>NOAA Weather Data for Analysis</b></h2>
            <p>By utilizing the NOAA API and a python script to collect and process the available data for weather warnings and watches this porject was aimed at providing additional information for how weather could impact services.</p>
          <h2><b>Retail Optimizations</b></h2>
            <p>A big data project focused on optimizing the data process available for retail temas to determine locations which were underserved. Used python and a large collection of data available to the team for geospatial processing and analysis.</p>
          <h2><b>Dataset QC Automation</b></h2>
            <p>Assisted with automating the quality control process needed to generate insight for a separate teams incongruent data when compared to our maintained datasets.</p>
          <h2><b>Zip5 PDF Stats Generation</b></h2>
            <p>A simple project assisting a team to generate PDFs with required data for analysis projects. Involved pulling in several data sources and processing, formatting, and outputting data into PDFs based on state.</p>
        </div>
      </div>
    </ProjectItemStyles>
  );
}

export default usps1;