import { v4 as uuidv4 } from 'uuid';

import carto from './cartoPreview.png';
import energy from './energyPreview.png';
import G573 from './G573Preview.png';
import ptolemy from './ptolemyPreview.png';
import vrGarden from './vrGardenPreview.png';
import uwcl from './UWCLPreview.png';
import graphics from './graphicsPreview.png';
import osrs from './osrsPreview.png';
import ff from './lethalPreview.png';
import usps from './uspsPreview.png';

const projects = [
  /*{
    id: uuidv4(),
    name: 'Fatal Federation',
    link: '/game',
    desc:
      "A recreation of the game Lethal League, but made only with React/JS, HTML Canvas, and a backend server for multiplayer. Supports controller",
    img: ff,
  },*/
  {
    id: uuidv4(),
    name: "USPS - Project Summaries",
    link: '/projects/usps',
    desc:
      'A writeup of various projects I have been involved with while working in the Geospatial Analytics team at USPS.',
    img: usps,
  },
  {
    id: uuidv4(),
    name: 'Advanced Geocomputing & Geospatial Big Data Analytics',
    link: '/projects/g573',
    desc:
      'A description of 573, which used Python, AWS, and ML/GIS libraries',
    img: G573,
  },
  /* {
    id: uuidv4(),
    name: 'Virtual Reality Garden',
    link: '/projects/vrgarden',
    desc:
      'A retrospective of a solo project where I created a VR Garden in Unity with C# and Blender',
    img: vrGarden,
  }, */
  /* {
    id: uuidv4(),
    name: 'Dynamic Election Cartograms',
    link: '/projects/cartogram',
    desc:
      'Election cartograms which dynamically generate based on state vote margins. Can be updated as results are released',
    img: carto,
  }, */
  {
    id: uuidv4(),
    name: "Medieval Ptolemaics: Undergraduate Capstone",
    link: '/projects/ptolemy',
    desc:
      'A Semiotic Analysis of Medieval Ptolemaics Over Time',
    img: ptolemy,
  },
  {
    id: uuidv4(),
    name: 'Are we doing enough? Wisconsin\'s continued reliance on coal energy',
    link: '/projects/energy',
    desc: 
      'A narrative exploration of Wisconsin\'s emerging energy landscape',
    img: energy,
  },
  {
    id: uuidv4(),
    name: 'UWCL Design Challenge 2020',
    link: '/projects/uwcl',
    desc:
      'A breakdown of the accelerated data collection and map design process of the weekend',
    img: uwcl,
  },
  // {
  //   id: uuidv4(),
  //   name: 'Computer Graphics',
  //   link: '/projects/graphics',
  //   desc:
  //     'My final project for CG, featuring hand-written shaders and models created solely with Three.js',
  //   img: graphics,
  // },
  {
    id: uuidv4(),
    name: 'Oldschool Runescape AI',
    link: '/projects/osrsbot',
    desc:
      'A bot created with Python employing image analysis techniques to automatically complete gameplay loops',
    img: osrs,
  },
];

export default projects;